import * as types from './mutation-types'
import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'
import { FORM_TYPE } from 'shared/AForm'

export const state = () => ({
  formsSettings: {}
})

function normalizeData(data) {
  const normalizedData = {}
  data.forEach(widgetSettings => {
    normalizedData[widgetSettings.Type] = widgetSettings
  })
  return normalizedData
}

export const getters = {
  getFormSettingsByType: ({ formsSettings }) => formType =>
    formsSettings[formType] || {}
}

export const actions = {
  async sendContactUsLead(_, { payload, headerParams }) {
    try {
      const settings = {
        headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
      }
      await this.$axiosV2.post(
        apiV2.marketing.submitContactUsLead(),
        payload,
        settings
      )
    } catch (e) {
      throw e
    }
  },
  async requestExpertise() {
    try {
      const {
        data: { Expertises }
      } = await this.$axiosV2.get(
        apiV2.marketing.getBecomeAContributorExpertises()
      )
      return Expertises
    } catch (e) {
      throw e
    }
  },
  async requestBecomeAContributor(_, { payload, headerParams }) {
    try {
      const settings = {
        headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
      }
      await this.$axiosV2.post(
        apiV2.marketing.submitBecomeAContributor(),
        payload,
        settings
      )
    } catch (e) {
      throw e
    }
  },
  async requestBecomeAContributorFileUpload(_, payload) {
    try {
      const { data } = await this.$axios.post(
        api.leads.becomeAContributorFileUpload(),
        payload
      )
      return data
    } catch (e) {
      throw e
    }
  },
  async requestFormsSettings({ commit, state }) {
    if (Object.values(state.formsSettings).length) return

    try {
      const { data } = await this.$axios.get(
        api.leads.getSubscriptionSettings(),
        {
          params: {
            types: Object.values(FORM_TYPE)
          }
        }
      )

      commit(types.SET_WIDGETS_SETTINGS, normalizeData(data))
      return data
    } catch (e) {
      throw e
    }
  },
  async sendNewsletter({ dispatch, rootGetters }, { payload, captchaKey }) {
    try {
      const settings = {
        headers: { GoogleCaptchaResponseKey: captchaKey }
      }
      await this.$axios.post(
        api.leads.newsletterSubscription(),
        payload,
        settings
      )

      if (rootGetters['auth/isLoggedIn']) {
        await dispatch('auth/requestCurrentUserDetails', null, {
          root: true
        })
      }
    } catch (e) {
      throw e
    }
  },
  async requestEventsNewsletterSubscription(_, { payload, captchaKey }) {
    try {
      const settings = {
        headers: { GoogleCaptchaResponseKey: captchaKey }
      }
      await this.$axios.post(
        api.leads.eventsNewsletterSubscription(),
        payload,
        settings
      )
    } catch (e) {
      throw e
    }
  },
  async requestPhoneVerification(_, params) {
    try {
      const data = await this.$axios.post(api.leads.verifyPhone(), null, {
        params
      })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestSubmitEbookLead(_, { payload, captchaKey }) {
    const settings = {
      headers: { GoogleCaptchaResponseKey: captchaKey }
    }

    try {
      const data = await this.$axios.post(
        api.leads.submitEbookLead(),
        payload,
        settings
      )
      return data
    } catch (e) {
      throw e
    }
  }
}

export const mutations = {
  [types.SET_WIDGETS_SETTINGS](state, settings) {
    state.formsSettings = settings
  }
}
