<template>
  <component
    v-observe-visibility="visibilityOptions"
    :is="currentComponent"
    :class="dynamicWrapperClass"
    :style="dynamicWrapperStyles"
    v-bind="wrapperAttrs"
    class="a-image__wrapper"
  >
    <!-- eslint-disable -->
    <!-- ToDo: Do not close img tag as it should be without /> -->
    <!-- prettier-ignore -->
    <img
      v-bind="imageAttributes"
      class="a-image__image"
      :style="dynamicImageStyles"
      @load="onImageLoad"
    >
    <!-- eslint-enable -->
  </component>
</template>

<script>
import { IMAGE_OBJECT_REMAP } from 'shared/AImage/enums'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import mixins from '@/utils/mixins'
import {
  HTML_TAG,
  CUSTOM_HTML_TAG,
  IMAGE_SETTINGS
} from '@/utils/helpers/processHtml/enums'
import { OBSERVER_CALLBACK_DEFAULT_THROTTLE } from 'enums/mutation-observer'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import {
  IMAGE_SIZE,
  BREAKPOINT_RANGE,
  OVER_CONTENT_IMAGE_WIDTH,
  Srcset
} from '@/utils/enums/images'
import { REL_ATTRIBUTE_BACKEND_ENUM } from '@fmpedia/enums'

const {
  ATTRIBUTES: { ASPECT_RATIO }
} = IMAGE_SETTINGS

const VISIBILITY_OFFSET = '100px'

const WRAPPER_COMPONENT = {
  LINK: CUSTOM_HTML_TAG.A_LINK,
  DIV: HTML_TAG.DIV
}

export default {
  name: 'AImage',
  inheritAttrs: false,
  mixins: [mixins.urlFormatters, hydrationHelpers],
  props: {
    image: propValidator([PROP_TYPES.OBJECT], false),
    src: propValidator([PROP_TYPES.STRING, PROP_TYPES.FILE], false),
    alt: propValidator([PROP_TYPES.STRING], false),
    width: propValidator([PROP_TYPES.NUMBER, PROP_TYPES.STRING], false),
    height: propValidator([PROP_TYPES.NUMBER, PROP_TYPES.STRING], false),
    objectFit: propValidator([PROP_TYPES.STRING], false, 'contain'),
    aspectRatio: propValidator(
      [PROP_TYPES.NUMBER, PROP_TYPES.STRING],
      false,
      null
    ),
    containerAspectRatio: propValidator(
      [PROP_TYPES.NUMBER, PROP_TYPES.STRING],
      false,
      null
    ),
    containerObjectPosition: propValidator(
      [PROP_TYPES.STRING],
      false,
      'center center'
    ),
    url: propValidator([PROP_TYPES.STRING, PROP_TYPES.OBJECT], false, ''),
    linkTrailingSlash: propValidator([PROP_TYPES.BOOLEAN], false),
    directives: propValidator(
      [PROP_TYPES.STRING],
      false,
      REL_ATTRIBUTE_BACKEND_ENUM.NOFOLLOW
    ),
    openInNewTab: propValidator(
      [PROP_TYPES.BOOLEAN, PROP_TYPES.STRING],
      false,
      null
    ),
    forceUrl: propValidator([PROP_TYPES.BOOLEAN], false, false),
    wrapperClass: propValidator([PROP_TYPES.STRING], false, ''),
    srcsetLocation: propValidator([PROP_TYPES.STRING], false, null),
    viewportOptimization: propValidator([PROP_TYPES.BOOLEAN], false, false),
    debug: propValidator([PROP_TYPES.BOOLEAN], false, false),
    linkAriaLabel: propValidator([PROP_TYPES.STRING], false),
    rounded: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  consts: {
    DEFAULT_ARIA_LABEL: {
      INTERNAL_PAGE: 'Link to internal page',
      INTERNAL_SECTION: 'Link to internal section'
    }
  },
  data() {
    return {
      containerWidth: 0,
      visibilityOptions: {
        callback: this.checkImageVisibility,
        intersection: {
          rootMargin: VISIBILITY_OFFSET
        },
        throttle: OBSERVER_CALLBACK_DEFAULT_THROTTLE
      },
      isImageInViewPort: false,
      wasImageShown: false,
      isImageLoaded: false,
      srcset: null,
      sizes: null
    }
  },
  computed: {
    currentComponent() {
      return this.url ? WRAPPER_COMPONENT.LINK : WRAPPER_COMPONENT.DIV
    },
    isFullImageDimensionsExist() {
      return !!this.requestedImageWidth && !!this.requestedImageHeight
    },
    isWidthAndHeightAvailable() {
      return !!(this.widthAttr && this.heightAttr)
    },
    isFitToContainer() {
      return !!this.containerAspectRatio && !this.aspectRatioAttr
    },
    dynamicWrapperClass() {
      return {
        ...(this.wrapperClass ? { [this.wrapperClass]: true } : false),
        'a-image--absolute':
          !!this.containerAspectRatio || !!this.aspectRatioAttr,
        'a-image--fit-to-container': this.isFitToContainer,
        'a-image--aspect-ratio-container':
          !!this.containerAspectRatio || this.isWidthAndHeightAvailable,
        'rounded-corners': this.rounded
      }
    },
    dynamicWrapperStyles() {
      if (!this.aspectRatioAttr && !this.containerAspectRatio) return null

      const aspectRatioStyles = this.$helper.generateAspectRatioStyle(
        this.containerAspectRatio || this.aspectRatioAttr,
        this.widthAttr || OVER_CONTENT_IMAGE_WIDTH
      )

      return {
        ...aspectRatioStyles,
        ...(this.backgroundPreviewStyles || {})
      }
    },
    dynamicImageStyles() {
      if (!this.isFitToContainer) return null

      return {
        objectPosition: this.containerObjectPosition
      }
    },
    backgroundPreviewStyles() {
      if (!this.isImageCssPreviewShouldBeRendered) {
        return null
      }

      const placeholderUrl = this.generateImageUrl(
        this.originalUrl,
        IMAGE_SIZE.WIDTH_50
      )

      return {
        background: `center / cover no-repeat url('${placeholderUrl}')`
      }
    },
    calculatedAspectRatio() {
      if (!this.isFullImageDimensionsExist) return +this.aspectRatio

      return Math.floor((+this.width / +this.height) * 100) / 100
    },
    aspectRatioAttr() {
      return this.calculatedAspectRatio
    },
    isAspectRatioSetWithoutWidthAndHeight() {
      return !this.width && !this.height && !this.isAspectRatioFlexible
    },
    isImageUrlHasSizeSuffixes() {
      if (!this.imageSource || this.isUrlDataOrBlob) return false

      return this.$helper.isImageUrlHasSizeSuffixes(this.originalUrl)
    },
    /**
     * If rendered Media Center image doesn't have width and height attribute
     * (i.e. Infographic image), we render it to fit content width.
     * If the actual image is small, it will have sizes by default according
     * to Media Center implementation on BO.
     * @returns {boolean}
     */
    isFullWidthRender() {
      if (!this.isAspectRatioFlexible || !this.isImageUrlHasSizeSuffixes)
        return false

      return !this.isFullImageDimensionsExist
    },
    widthAttr() {
      if (
        this.isAspectRatioSetWithoutWidthAndHeight ||
        this.isFullWidthRender
      ) {
        return OVER_CONTENT_IMAGE_WIDTH
      }

      if (
        this.requestedImageHeight &&
        !this.requestedImageWidth &&
        this.calculatedAspectRatio
      ) {
        return Math.floor(
          this.requestedImageHeight * this.calculatedAspectRatio
        )
      }

      return this.requestedImageWidth
    },
    heightAttr() {
      if (this.isAspectRatioSetWithoutWidthAndHeight) {
        return Math.floor(OVER_CONTENT_IMAGE_WIDTH / this.aspectRatioAttr)
      }

      if (this.isFullWidthRender) return null

      const canHeightBeCalculated =
        this.requestedImageWidth && this.calculatedAspectRatio !== 0

      return (
        this.requestedImageHeight ||
        (canHeightBeCalculated
          ? Math.floor(this.requestedImageWidth / this.calculatedAspectRatio)
          : null)
      )
    },
    imageAttributes() {
      return {
        ...(this.isSrcsetUsed
          ? this.imageSourceDataWithSrcset
          : this.imageSourceDataWithoutSrcset),
        alt: this.imageAlt,
        ...this.$attrs,
        [ASPECT_RATIO]: this.aspectRatioAttr,
        width: this.widthAttr,
        height: this.heightAttr || null,
        ...(this.viewportOptimization
          ? { fetchpriority: 'high' }
          : { loading: 'lazy' })
      }
    },
    imageSourceDataWithoutSrcset() {
      return {
        src: this.imageUrl
      }
    },
    imageSourceDataWithSrcset() {
      return {
        srcset: this.srcset,
        sizes: this.sizes
      }
    },
    linkAriaLabelWithFallback() {
      if (!this.url) return null

      if (this.linkAriaLabel) {
        return this.linkAriaLabel
      }

      if (this.$helper.isObject(this.url)) {
        return this.$options.consts.DEFAULT_ARIA_LABEL.INTERNAL_PAGE
      }

      const { address, hash } = this.$helper.parseUrl(this.url)

      if (!address && hash) {
        return this.$options.consts.DEFAULT_ARIA_LABEL.INTERNAL_SECTION
      }

      if (this.$helper.isStringStartsWith('/', address)) {
        return this.$options.consts.DEFAULT_ARIA_LABEL.INTERNAL_PAGE
      }

      let hostname
      try {
        const parsedUrl = new URL(this.url)
        hostname = parsedUrl.hostname
      } catch (err) {
        this.$errorHandler(err, this, { showMessage: false })
      }
      return hostname ? `Link to ${hostname}` : this.imageAlt
    },
    wrapperAttrs() {
      if (!this.url) return {}

      return {
        to: this.url,
        directive: this.directives,
        openInNewTab: this.openInNewTab,
        ariaLabel: this.linkAriaLabelWithFallback
      }
    },
    remappedImage() {
      return this.$helper.processResponse(this.image, IMAGE_OBJECT_REMAP)
    },
    requestedImageWidth() {
      return +this.width || null
    },
    requestedImageHeight() {
      return +this.height || null
    },
    requestedImageSizeBreakpointName() {
      const imageWidth = this.requestedImageWidth
        ? Math.min(this.requestedImageWidth, this.containerWidth)
        : this.containerWidth

      return this.getImageBreakpointNameByWidth(imageWidth)
    },
    imageSizeBreakpointName() {
      return this.isImageInViewPort || this.wasImageShown
        ? this.requestedImageSizeBreakpointName
        : IMAGE_SIZE.WIDTH_50
    },
    imageSource() {
      return this.remappedImage?.url || this.src || null
    },
    imageUrl() {
      if (!this.imageSource) return null

      if (this.isUrlDataOrBlob) {
        return this.generateImageUrl(
          this.imageSource,
          this.imageSizeBreakpointName
        )
      }

      if (this.isSrcsetUsed) return null

      if (this.isAmp) {
        const ampImageWidth = this.width || IMAGE_SIZE.WIDTH_775
        const ampImageBreakpoint = this.getImageBreakpointNameByWidth(
          ampImageWidth
        )
        return this.generateImageUrl(this.imageSource, ampImageBreakpoint)
      }

      return this.generateImageUrl(
        this.originalUrl,
        this.imageSizeBreakpointName
      )
    },
    isAspectRatioFlexible() {
      return Number(this.calculatedAspectRatio) === 0
    },
    imageAlt() {
      return this.$helper.getAltTextForMediaCenterImage(this.image) || this.alt
    },
    isAmp() {
      return this.$helper.isAmpPage(this.$route.name)
    },
    isImagePreviewShouldBeRendered() {
      if (this.viewportOptimization) return false

      return (
        (this.isImageInViewPort || this.wasImageShown) && !this.isImageLoaded
      )
    },
    isImageShouldBeRendered() {
      if (this.viewportOptimization) return true

      return process.client && this.isImageLoaded
    },
    isImageCssPreviewShouldBeRendered() {
      if (!this.isImageUrlHasSizeSuffixes) return false

      return this.viewportOptimization && this.isWidthAndHeightAvailable
    },
    isSrcsetUsed() {
      return !!this.srcsetLocation && !!this.isImageUrlHasSizeSuffixes
    },

    isUrlDataOrBlob() {
      return (
        this.isUrlInstanceOfBlob(this.imageSource) ||
        this.isUrlDataString(this.imageSource)
      )
    },
    originalUrl() {
      if (!this.imageSource || this.isUrlDataOrBlob) {
        return this.imageSource
      }

      return this.$helper.replaceImageUrlSuffixWithOriginal(this.imageSource)
    },
    recalculateSrcsetDataTrigger() {
      return this.$helper.getBinaryStringFromBooleanArray([
        this.isSrcsetUsed,
        this.isImagePreviewShouldBeRendered,
        this.isImageShouldBeRendered
      ])
    }
  },
  watch: {
    $_hydrationHelpers_windowWidth: {
      immediate: true,
      handler() {
        if (!process.browser) return

        this.$nextTick(() => {
          const container = this.getImageContainer()
          if (container) {
            this.containerWidth = container.clientWidth
          }
        })
      }
    },
    recalculateSrcsetDataTrigger: {
      immediate: true,
      handler: async function() {
        if (
          !this.isSrcsetUsed ||
          (!this.isImagePreviewShouldBeRendered &&
            !this.isImageShouldBeRendered &&
            !this.isAmp)
        ) {
          return
        }
        const srcset = new Srcset({
          originalUrl: this.originalUrl,
          srcsetLocation: this.srcsetLocation,
          maxWidth: this.requestedImageWidth,
          isImagePlaceholder: this.isImagePreviewShouldBeRendered
        })
        this.sizes = srcset.generateSizesLine()
        this.srcset = srcset.generateSrcsetLine()
      }
    }
  },
  methods: {
    getImageContainer() {
      if (!this.$el) return null

      let parentElement = this.$el.parentElement
      if (!parentElement) return null

      const imageTags = [HTML_TAG.FIGURE, HTML_TAG.LINK]
      if (
        parentElement.tagName &&
        imageTags.includes(parentElement.tagName.toLowerCase())
      ) {
        parentElement = parentElement.parentElement
      }

      return parentElement || null
    },
    getImageBreakpointNameByWidth(imageWidth) {
      return Object.values(IMAGE_SIZE).find(val => {
        const [min, max] = BREAKPOINT_RANGE[val]

        return imageWidth >= min && imageWidth <= max
      })
    },
    isUrlInstanceOfBlob(url) {
      if (!process.client || !url) return false

      return url instanceof Blob
    },
    isUrlDataString(url) {
      if (!url || this.isUrlInstanceOfBlob(url)) return false

      const localFileRegex = /(^data:.+)|(^blob:.+)/

      return !!url.match(localFileRegex)
    },
    generateImageUrl(originalUrl, neededSize) {
      if (originalUrl == null) {
        return originalUrl
      }

      if (this.isUrlInstanceOfBlob(originalUrl)) {
        const urlCreator = window.URL || window.webkitURL
        return urlCreator.createObjectURL(originalUrl)
      }

      if (this.isUrlDataString(this.originalUrl)) {
        return originalUrl
      }

      const handler = this.forceUrl ? this.$helper.forceUrlFetch : url => url

      return handler(
        this.$helper.replaceImageUrlSuffix({
          originalUrl,
          neededSize
        })
      )
    },
    checkImageVisibility(visibility) {
      this.isImageInViewPort = visibility
      if (visibility && !this.wasImageShown) {
        this.wasImageShown = true
        this.$emit('full-size')
      }
    },
    onImageLoad() {
      this.isImageLoaded = true
    }
  }
}
</script>

<style lang="scss">
.a-image__wrapper {
  width: 100%; /* default value for AImage component, can be changed by setting class to wrapper with defined width */
  position: relative;
  overflow: hidden;
  display: block;

  .a-image__image {
    max-width: 100%;
  }

  &.a-image--aspect-ratio-container {
    max-width: 100%;

    .a-image__image {
      max-height: 100%;
    }
  }

  &.a-image--absolute {
    .a-image__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.a-image--fit-to-container {
    .a-image__image {
      object-fit: contain;
    }
  }
}
</style>
