const marketing = {
  slug: 'f/marketing',
  submitSendMeAnOffer: offerId => `${marketing.slug}/offers/${offerId}/submit`,
  submitContactUsLead: () => `${marketing.slug}/contact-us/submit`,
  submitBecomeAContributor: () => `${marketing.slug}/contribution/submit`,
  getBecomeAContributorExpertises: () =>
    `${marketing.slug}/contribution/expertises`,
  submitInsiderReportLead: () =>
    `${marketing.slug}/intelligence/insider-report/submit`,
  submitCustomMadeReportLead: () =>
    `${marketing.slug}/intelligence/custom-report/submit`,
  submitJoinEventsLead: () => `${marketing.slug}/events/sticky-button/submit`
}

export default marketing
