import ouibounce from 'ouibounce'
import { MODAL } from '@/components/_modals/AModalWrapper'
import { ROUTE_NAME } from 'enums/routes'

export const PURCHASE_IN_PROGRESS_STORAGE_KEY = 'purchase-in-progress'

const isCurrentPageMarkedAsPurchaseInProgress = (helper, route) => {
  const purchaseInProgressInfo =
    helper.parseJSON(localStorage.getItem(PURCHASE_IN_PROGRESS_STORAGE_KEY)) ||
    {}

  if (
    route.name === ROUTE_NAME.INTELLIGENCE_PRODUCTS_ANNUAL &&
    purchaseInProgressInfo[ROUTE_NAME.INTELLIGENCE_PRODUCTS_ANNUAL]
  ) {
    return true
  }

  if (
    route.name === ROUTE_NAME.INTELLIGENCE_PRODUCTS_QIR_SLUG &&
    purchaseInProgressInfo[route.params.slug]
  ) {
    return true
  }

  return false
}

export default {
  data() {
    return {
      ouibounceInstance: null
    }
  },
  methods: {
    async $_exitFromPage_initOuibounce() {
      this.ouibounceInstance = ouibounce(null, {
        callback: this.$_exitFromPage_exitPageHandler,
        aggressive: true
      })
    },
    $_exitFromPage_exitPageHandler() {
      if (isCurrentPageMarkedAsPurchaseInProgress(this.$helper, this.$route)) {
        return
      }

      this.$helper.openModal(MODAL.QIR_POPUP_MESSAGE)
    }
  },
  beforeMount() {
    this.$_exitFromPage_initOuibounce()
  },
  beforeDestroy() {
    this.ouibounceInstance.disable()
  }
}
