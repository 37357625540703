import * as types from './mutation-types'
import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'
import { valuesToLowercase } from '~/plugins/helper'
import { handleArticleSlug } from '@/utils/helpers/articles'
import { cachedRequest } from '@/utils/jobRequestApiMethods'
import { MIGRATED_CATEGORY_SLUG } from 'enums/fl/fl-categories'
import {
  generateArticleData,
  generateArticleSlotData,
  generateHomepageArticleSlotData,
  generateRelatedArticleSlotData
} from '@/utils/helpers/models/article'
import { API_VERSION } from 'enums/api'

const ALL_NEWS_PAGE_TO_CACHE = 0

export const state = () => ({
  articles: {},
  tags: [],
  isCurrentPageArticle: false
})

export const getters = {
  listOfArticles: state => state.articles,
  isCurrentPageArticle: state => state.isCurrentPageArticle
}

export const actions = {
  async requestCheckSlug({ commit }, params) {
    try {
      const { data } = await this.$axios.get(api.articles.checkSlug(), {
        params: { slug: handleArticleSlug(params.slug) }
      })

      commit(
        types.SET_IS_CURRENT_PAGE_ARTICLE,
        !data.IsSubCategory && data.IsArticle
      )

      return data
    } catch (err) {
      throw err
    }
  },
  async requestHomepageFmArticles() {
    try {
      const {
        data: {
          TopArticle,
          LatestNewsArticles,
          EducationArticles,
          MostViewedArticles,
          ThoughtLeadershipArticles
        },
        lastModified
      } = await cachedRequest({
        method: this.$axios.get,
        args: [api.homepage.getFmArticles()],
        apiVersion: API_VERSION.V1
      })

      return {
        LatestNewsArticles: LatestNewsArticles.map(categoryData => ({
          ...categoryData,
          Articles: (categoryData.Articles || []).map(article =>
            generateHomepageArticleSlotData(article, this)
          )
        })),
        TopArticles: [generateHomepageArticleSlotData(TopArticle, this)],
        MostViewedArticles: (MostViewedArticles || []).map(article =>
          generateHomepageArticleSlotData(article, this)
        ),
        EducationArticles: (EducationArticles || []).map(article =>
          generateHomepageArticleSlotData(article, this)
        ),
        ThoughtLeadershipArticles: (
          ThoughtLeadershipArticles || []
        ).map(article => generateHomepageArticleSlotData(article, this)),
        lastModified
      }
    } catch (err) {
      throw err
    }
  },
  async requestDirectoryCategories() {
    try {
      const {
        data: { Categories },
        lastModified
      } = await cachedRequest({
        method: this.$axios.get,
        args: [api.homepage.getDirectoryCategories()],
        apiVersion: API_VERSION.V1
      })

      return {
        Categories,
        lastModified
      }
    } catch (err) {
      throw err
    }
  },
  async requestDirectoryCompanies() {
    try {
      const {
        data: { Companies },
        lastModified
      } = await cachedRequest({
        method: this.$axios.get,
        args: [api.homepage.getDirectoryCompanies()],
        apiVersion: API_VERSION.V1
      })

      return {
        Companies,
        lastModified
      }
    } catch (err) {
      throw err
    }
  },
  async requestDirectoryNews() {
    try {
      const params = { take: 2, page: 0 }
      const {
        data: { News },
        lastModified
      } = await cachedRequest({
        method: this.$axios.get,
        args: [api.fmDirArticles.getHomepageDirectoryNews(), { params }],
        apiVersion: API_VERSION.V1
      })

      return {
        News,
        lastModified
      }
    } catch (err) {
      throw err
    }
  },
  async requestHomepageVideos(_, params) {
    try {
      const {
        data: { Videos },
        lastModified
      } = await cachedRequest({
        method: this.$axios.get,
        args: [api.homepage.getHomepageVideos(), { params }],
        apiVersion: API_VERSION.V1
      })

      return { Videos, lastModified }
    } catch (err) {
      throw err
    }
  },
  async requestHomepageEvents() {
    try {
      const { data: Events, lastModified } = await cachedRequest({
        method: this.$axios.get,
        args: [api.homepage.getHomepageEvents()],
        apiVersion: API_VERSION.V1
      })

      return { Events, lastModified }
    } catch (err) {
      throw err
    }
  },
  async requestHomepageFlMarketNews() {
    try {
      const {
        data: { Articles },
        lastModified
      } = await cachedRequest({
        method: this.$axios.get,
        args: [api.homepage.getFlLatestNews()],
        apiVersion: API_VERSION.V1
      })

      return { Articles, lastModified }
    } catch (err) {
      throw err
    }
  },
  async requestHomepageFlTechnicalAnalysisNews() {
    try {
      const {
        data: { Articles },
        lastModified
      } = await cachedRequest({
        method: this.$axios.get,
        args: [
          api.homepage.getFlNewsByCategory(
            MIGRATED_CATEGORY_SLUG.TECHNICAL_ANALYSIS
          )
        ],
        apiVersion: API_VERSION.V1
      })

      return { Articles, lastModified }
    } catch (err) {
      throw err
    }
  },
  async requestMostViewedArticles(_, params) {
    try {
      let res
      if (!params.isSponsored) {
        res = await cachedRequest({
          method: this.$axios.get,
          args: [
            api.articles.getMostViewedArticles(),
            {
              params
            }
          ],
          apiVersion: API_VERSION.V1
        })
      } else {
        res = await this.$axios.get(api.articles.getMostViewedArticles(), {
          params
        })
      }
      return {
        mostViewedArticles: res.data,
        mostViewedArticlesLastModified: res.lastModified
      }
    } catch (err) {
      throw err
    }
  },
  async requestEducationArticles(_, params) {
    try {
      const {
        data
      } = await this.$axios.get(api.articles.getEducationArticles(), { params })
      return data
    } catch (err) {
      throw err
    }
  },
  async requestAllNews(_, params) {
    try {
      let res
      if (params.page === ALL_NEWS_PAGE_TO_CACHE) {
        res = await cachedRequest({
          method: this.$axios.get,
          args: [
            api.articles.getAllNews(),
            {
              params
            }
          ],
          apiVersion: API_VERSION.V1
        })
        res.data.Articles = (res.data.Articles || []).map(article =>
          generateArticleSlotData(
            { ...article, LastModified: `${res.lastModified}-${article.Id}` },
            this
          )
        )
        return res.data
      }

      res = await this.$axios.get(api.articles.getAllNews(), {
        params
      })
      res.data.Articles = (res.data.Articles || []).map(article =>
        generateArticleSlotData(article, this)
      )

      return res.data
    } catch (err) {
      throw err
    }
  },
  async requestArticlePageData({ dispatch }, payload) {
    const isPreview = !!payload.previewId

    const articleDataPromise = dispatch('requestArticleContentBySlug', payload)
    const relatedArticlesPromise = isPreview
      ? null
      : dispatch('requestRelatedArticlesBySlug', payload)
    const relatedVideosPromise = dispatch('requestHomepageVideos', {
      excludeTopVideo: true
    })
    /**
     * We should request form settings here to avoid DOM mismatch when the
     * Newsletter form is rendered on the Article page Sidebar.
     */
    const newsletterRequestPromise = dispatch(
      'leads/requestFormsSettings',
      null,
      { root: true }
    )

    const [
      articleData,
      relatedArticles,
      { Videos: relatedVideos }
    ] = await Promise.all([
      articleDataPromise,
      relatedArticlesPromise,
      relatedVideosPromise,
      newsletterRequestPromise
    ])

    return {
      articleData,
      relatedArticles: isPreview
        ? articleData.RelatedArticles
        : relatedArticles,
      relatedVideos
    }
  },
  async requestArticleEbook(_, params) {
    const method = params.previewId
      ? 'getPreviewArticleEbook'
      : 'getArticleEbook'
    const apiParams = params.previewId ? { id: params.previewId } : params

    try {
      const { data } = await this.$axios.get(api.articles[method](), {
        params: apiParams
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async requestArticleContentBySlug(
    { commit, dispatch },
    { previewId, articleSlug }
  ) {
    if (!articleSlug && !previewId) return null

    const method = previewId ? 'getArticlePreview' : 'getArticleBySlug'
    const payload = previewId
      ? null
      : {
          params: {
            articleSlug: handleArticleSlug(articleSlug)
          }
        }

    try {
      /**  Request article metaData from backend**/
      const { data: articleMetaData } = await this.$axiosV2.get(
        apiV2.articles[method](previewId),
        payload
      )

      const d = new Date()
      var datestring =
        d.getDate() +
        '-' +
        (d.getMonth() + 1) +
        '-' +
        d.getFullYear() +
        ' ' +
        d.getHours() +
        ':' +
        d.getMinutes() +
        ':' +
        d.getSeconds() +
        ' ' +
        d.getTimezoneOffset()

      console.time(`S3, ${datestring}, ${articleSlug}`)

      /** Request article content from S3Bucket **/
      const { data: articleContent } = await this.$axios.get(
        articleMetaData.Url,
        {
          params: { date: new Date() }
        }
      )

      console.timeEnd(`S3, ${datestring}, ${articleSlug}`)

      if (previewId) {
        commit(`seo/${types.SET_PAGE_SEO}`, articleMetaData.Seo, {
          root: true
        })
        dispatch('removePreviewArticleContent', previewId)

        Object.keys(articleMetaData.RelatedArticles).forEach(section => {
          articleMetaData.RelatedArticles[section] = (
            articleMetaData.RelatedArticles[section] || []
          ).map(article => {
            return generateRelatedArticleSlotData(article, this)
          })
        })
      }

      return {
        ...generateArticleData(articleContent, articleMetaData, this),
        ...(previewId
          ? { RelatedArticles: articleMetaData.RelatedArticles }
          : {})
      }
    } catch (e) {
      throw e
    }
  },
  async requestRelatedArticlesBySlug({ commit, dispatch }, { articleSlug }) {
    if (!articleSlug) return null

    const payload = {
      params: { articleSlug: handleArticleSlug(articleSlug) }
    }

    try {
      const { data } = await this.$axiosV2.get(
        apiV2.articles.getRelatedArticlesBySlug(),
        payload
      )

      Object.keys(data).forEach(section => {
        data[section] = (data[section] || []).map(article => {
          return generateRelatedArticleSlotData(article, this)
        })
      })

      return data
    } catch (e) {
      throw e
    }
  },
  async removePreviewArticleContent(_, previewId) {
    try {
      await this.$axiosV2.delete(apiV2.articles.deleteArticlePreview(previewId))
    } catch (e) {
      throw e
    }
  },
  async requestArticleByCategory(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.articles.getArticleByCategory(),
        {
          params: valuesToLowercase(params)
        }
      )

      data.Articles = (data.Articles || []).map(article => {
        return generateArticleSlotData(article, this)
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async requestArticlesBySubcategory(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.articles.getArticlesBySubcategory(),
        {
          params: valuesToLowercase(params)
        }
      )

      data.Articles = (data.Articles || []).map(article => {
        return generateArticleSlotData(article, this)
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async createSendMeAnOffer(_, { payload, headerParams, pathParams }) {
    try {
      const { offerId } = pathParams
      const settings = {
        headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
      }

      await this.$axiosV2.post(
        apiV2.marketing.submitSendMeAnOffer(offerId),
        payload,
        settings
      )
    } catch (err) {
      throw err
    }
  },
  async requestAllNewsPinnedCategories() {
    try {
      const {
        data: { Categories },
        lastModified
      } = await cachedRequest({
        method: this.$axios.get,
        args: [api.articles.getAllNewsPinnedCategories()],
        apiVersion: API_VERSION.V1
      })

      return {
        categoriesStrip: Categories || [],
        categoriesStripLastModified: lastModified
      }
    } catch (err) {
      throw err
    }
  },
  async requestCategoryPagePinnedSubcategories(_, params) {
    try {
      const {
        data: { SubCategories }
      } = await this.$axios.get(
        api.articles.getCategoryPagePinnedSubcategories(),
        { params }
      )
      return SubCategories || []
    } catch (err) {
      throw err
    }
  },
  async requestMigratedArticleSlugsByShortLink(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.articles.getMigratedArticleSlugs(),
        {
          params
        }
      )

      if (!data) return null

      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_ARTICLES](state, data) {
    state.articles = data
  },
  [types.SET_IS_CURRENT_PAGE_ARTICLE](state, isArticle) {
    state.isCurrentPageArticle = isArticle
  },
  [types.SET_TAGS](state, data) {
    state.tags = data
  }
}
